import React, { Fragment } from 'react';
import { Link } from 'gatsby'
import Logo from '../components/graphics/logo';
import Container from '../components/container';
import Header3 from '../components/header3';
import Paragraph from '../components/paragraph';
import { HelmetDatoCms } from 'gatsby-source-datocms';

const NotFoundPage = () => {
  return (
    <Fragment>
      <HelmetDatoCms title="Bezpieczny Balkon - 404: Not found"></HelmetDatoCms>
      <main>
        <Container className="flex flex-col items-center text-gray-800 space-y-8">
          <Link to='/'>
            <Logo></Logo>
          </Link>
          <Header3>404</Header3>
          <Paragraph>Strona o podanym adresie nie istnieje :|</Paragraph>
          <Link
            className='w-full md:w-auto button-main c_interactive bg-primary text-white px-16 active:outline-none active:bg-primaryDark'
            to='/'
            title='Home'>
            Wróć na stronę główną
          </Link>
        </Container>
      </main>
    </Fragment>
  )
}

export default NotFoundPage
